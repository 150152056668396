var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col vs-con-input-label"},[(_vm.label)?_c('label',{staticClass:"vs-input--label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('flat-pickr',{attrs:{"placeholder":_vm.placeholder,"config":{
            altInput: true,
            altFormat: _vm.withTime ? 'Y-m-d H:i' : 'Y-m-d',
            altInputClass: 'vs-inputx vs-input--input normal',
            wrap: false,
            enableTime: _vm.withTime,
            time_24hr: true,
            defaultDate: _vm.defaultDate ? _vm.defaultDate.toISO() : null,
            minDate: _vm.minDate ? _vm.minDate.toISO() : null,
            maxDate: _vm.maxDate ? _vm.maxDate.toISO() : null,
            minuteIncrement: 1
        },"disabled":_vm.disabled},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }