import axios from '@/axios.js'

export default {
    getResortsByApp: async (applicationUuid) => {
        try {
            const response = await axios.editorapi.get(`/apps/v3/${applicationUuid}/resorts`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getResorts: async (params, page) => {
        try {
            const response = await axios.adminapi.get(`/v4/resorts/`, { params: {
                ...params,
                page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
